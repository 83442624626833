@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "onest", sans-serif !important;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
  /* -ms-overflow-style: none; */
  overflow-y: scroll;
  background-color: white !important;
}

.styled-checkbox {
  @apply accent-green-500;
}

.font-ppmigra {
  font-family: "pp-migra" !important;
}
.font-onestLight {
  font-family: "onest-light" !important;
}
.font-onestThin {
  font-family: "onest-thin" !important;
}
.font-onestRegular {
  font-family: "onest-regular" !important;
}
.font-onestBold {
  font-family: "onest-bold" !important;
}
.font-onestMedium {
  font-family: "onest-medium" !important;
}

.toast-message {
  background: #f1ffef !important;
  color: #161718 !important;
  font-size: 14px;
  border-bottom: 3px #18af04 !important;
  padding: 30px 20px;
}
.toast-success {
  background: rgb(243, 255, 243) !important;
  border-bottom: 3px #18af04 !important;
}
.toast-error {
  background: rgb(252, 243, 243) !important;
  border-bottom: 3px red !important;
}
.toast-info {
  background: rgb(199, 199, 246) !important;
}
.Toastify__progress-bar--success {
  background-color: green !important;
}

.Toastify__progress-bar--info {
  background-color: blue !important;
}

.Toastify__progress-bar--warning {
  background-color: orange !important;
}
/* ONEST */
@font-face {
  font-family: "onest-thin";
  src: url("./asset/font/Onest/TTF/OnestThin1602-hint.ttf");
  font-display: swap;
}
@font-face {
  font-family: "onest-light";
  src: url("./asset/font/Onest/TTF/OnestLight1602-hint.ttf");
  font-display: swap;
}
@font-face {
  font-family: "onest-regular";
  src: url("./asset/font/Onest/TTF/OnestRegular1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-bold";
  src: url("./asset/font/Onest/TTF/OnestBold1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-medium";
  src: url("./asset/font/Onest/TTF/OnestMedium1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-black";
  src: url("./asset/font/Onest/TTF/OnestBlack1602-hint.ttf");
  font-display: swap;
}

/* PP MIGRA */
@font-face {
  font-family: "pp-migra";
  src: url("/src/asset/font/PP Migra/PPMigra-Bold.ttf");
  font-display: swap;
}

#formattedText {
  white-space: pre-line;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}
.animate-faster.animate__fadeInUp {
  animation-duration: 0.4s;
}

/* Hide the up and down arrows in Chrome, Safari, and other Webkit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the up and down arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Define the marquee animation */
.marquee {
  /* overflow: hidden; */
  /* white-space: nowrap; */
  display: block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
